import React, { useEffect } from "react"
import styled from "styled-components"
import Button from "../components/button"
import Layout from "../components/layout"

const StyledRepPage = styled.div``

const RepPage = () => {
  const onMount = () => {
    const content = document.querySelector(".index-content")
    setTimeout(() => {
      content.style.transform = "translateY(0)"
      content.style.opacity = 1
    }, 200)
  }

  useEffect(() => {
    onMount()
  }, [])
  return (
    <Layout title="Reparation">
      <StyledRepPage className="page">
        <div />
        <div className="index-content">
          <h1>Trasig surfbräda?</h1>
          <p>
            Inga problem, vi lagar din utrustning så att du snabbt kan ut och
            åka igen. Skicka ett mejl till oss med en kort beskrivning av ditt
            ärende, så återkommer vi inom 24 timmar.
          </p>
          <a
            href="mailto:sharp4@live.se?subject=Beställning%20%E2%80%93%20Lagning%20och%20reparation"
            className="order-button"
          >
            <Button>Mejla oss</Button>
          </a>
        </div>
        <div />
      </StyledRepPage>
    </Layout>
  )
}

export default RepPage
